import displayErrorMessage from '../components/Utils/Errors/DisplayErrorMessage';
import { Carriers } from '../utils/enums';

const ls = require('local-storage');
const axios = require('axios');

export const saveShipment = async (
  shippingCarrier,
  nameSender,
  lastNameSender,
  cellPhoneSender,
  emailSender,
  docTypeSender,
  docSender,
  stree1Sender,
  street2Sender,
  indicationsSender,
  cityCodeSender,
  nameReceiver,
  lastNameReceiver,
  cellPhoneReceiver,
  emailReceiver,
  docTypeReceiver,
  docReceiver,
  stree1Receiver,
  street2Receiver,
  indicationsReceiver,
  cityCodeReceiver,
  packages,
  declaredValue,
  totalValueToPay,
  isMerchandise,
  externalId,
  ownerid,
  paymentType,
  pickupDate,
  countrySender,
  countryReceiver,
  deliveryType,
) => {
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/shipment`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}`, 'owner-id': ownerid };
  const data = {
    carrier: shippingCarrier,
    sender: {
      name: nameSender,
      lastName: lastNameSender,
      phone: cellPhoneSender,
      email: emailSender,
      docType: docTypeSender,
      docNumber: docSender,
      street1: stree1Sender,
      street2: street2Sender,
      indications: indicationsSender,
      cityCode: cityCodeSender,
      country: countrySender,
    },
    receiver: {
      name: nameReceiver,
      lastName: lastNameReceiver,
      phone: cellPhoneReceiver,
      email: emailReceiver,
      docType: docTypeReceiver,
      docNumber: docReceiver,
      street1: stree1Receiver,
      street2: street2Receiver,
      indications: indicationsReceiver,
      cityCode: cityCodeReceiver,
      country: countryReceiver,
    },
    packages,
    params: {
      declaredValue,
      isMerchandise,
      totalValueToPay,
      externalId,
      ownerid,
      paymentType,
      deliveryType,
    },
    pickupDate: pickupDate.toISOString(),
  };
  try {
    const result = await axios({ method: 'post', url, headers, data });
    return { shipmentId: result.data.id };
  } catch (e) {
    displayErrorMessage(e, 'Error creando shipment');
  }
  return undefined;
};

export const quoteShipment = async (
  cityCodeSender,
  street1Sender,
  street2Sender,
  countrySender,
  cityCodeReceiver,
  street1Receiver,
  street2Receiver,
  countryReceiver,
  declaredValue,
  totalValueToPay,
  paymentType,
  isMerchandise,
  packages,
) => {
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/public/quotation`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  const data = {
    sender: {
      cityCode: cityCodeSender,
      street1: street1Sender,
      street2: street2Sender,
      country: countrySender,
    },
    receiver: {
      cityCode: cityCodeReceiver,
      street1: street1Receiver,
      street2: street2Receiver,
      country: countryReceiver,
    },
    details: {
      declaredValue,
      isPrePaid: paymentType === 'Pre_Paid',
      isMerchandise,
      totalValueToPay,
    },
    packages,
  };
  try {
    const result = await axios({ method: 'post', url, data, headers });
    let coordinadoraQuotation = 0;
    let servientregaQuotation = 0;
    result.data.map((quotation) => {
      switch (quotation.carrier) {
        case Carriers.COORDINADORA:
          coordinadoraQuotation = quotation.price;
          break;
        case Carriers.SERVIENTREGA:
          servientregaQuotation = quotation.price;
          break;
        default:
          break;
      }
      return { coordinadoraQuotation, servientregaQuotation };
    });

    return {
      coordinadoraQuotation,
      servientregaQuotation,
    };
  } catch (e) {
    displayErrorMessage(e, 'Error realizando cotiazión');
  }
  return {
    coordinadoraQuotation: 0,
    servientregaQuotation: 0,
  };
};

export const createGuide = async (shipmentId, ownerId) => {
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/public/shipment/${shipmentId}/guide/`;
  const params = {
    ownerId,
  };
  try {
    const result = await axios({ method: 'post', url, params, headers });
    return {
      trackingNumber: result.data.trackingNumber,
      pdf: result.data.pdf,
    };
  } catch (e) {
    displayErrorMessage(e, 'Error creando guía');
  }
  return undefined;
};

export const schedulePickUp = async (shipmentId) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api`;
  url += `/shipment/${shipmentId}/scheduled-pickup`;

  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };

  try {
    const result = await axios({ method: 'post', url, headers });
    return {
      message: result.data.message,
    };
  } catch (e) {
    return {
      message: undefined,
    };
  }
};

export const cancelShipment = async (shipmentId) => {
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/shipment/${shipmentId}/cancel`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  const data = {
    shipmentId,
  };
  try {
    const result = await axios({ method: 'post', url, data, headers });
    return {
      message: result.data,
    };
  } catch (e) {
    return {
      message: undefined,
    };
  }
};

export const validateRoute = async (origin, destination) => {
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/public/validate-routes`;
  const data = {
    cityCodeDestination: destination,
    cityCodeOrigin: origin,
  };
  try {
    const result = await axios({ method: 'post', url, data });
    return {
      message: result.data,
    };
  } catch (e) {
    return {
      message: undefined,
    };
  }
};
