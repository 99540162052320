import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, DatePicker, Row, Col, Input } from 'antd';
import { CarOutlined, LoadingOutlined } from '@ant-design/icons';
import GeneralInfoTable from './GeneralInfoTable';
import Errors from '../Utils/Errors/Errors';
import { fetchPickUps } from '../../gateway/fetchShipment';

class PickUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { current: 1, pageSize: 10 },
      pickups: [],
      since: moment(new Date().setDate(new Date().getDate() - 1)),
      to: moment().endOf('day'),
      trackingNumber: undefined,
      ownerId: undefined,
      loading: false,
    };
    this.reload = this.reload.bind(this);
    this.fetchPickUps = this.fetchPickUps.bind(this);
  }

  reload() {
    this.setState(
      {
        pagination: { current: 1, pageSize: 10 },
        pickups: [],
      },
      () => this.fetchPickUps(this.state.pagination),
    );
  }

  async fetchPickUps() {
    this.setState({ loading: true, pickups: [] });
    const { pagination, trackingNumber, ownerId, since, to } = this.state;
    if (trackingNumber) {
      const { shipments } = await fetchPickUps(pagination, trackingNumber);
      this.setState({ loading: false, pickups: shipments });
    } else {
      const { shipments, totalPages } = await fetchPickUps(
        pagination,
        trackingNumber,
        ownerId,
        since,
        to,
      );
      pagination.total = totalPages;
      this.setState({
        loading: false,
        pickups: shipments,
        pagination,
      });
    }
  }

  componentDidMount() {
    this.fetchPickUps();
  }

  render() {
    const { pickups, pagination, loading } = this.state;
    return (
      <div>
        <CarOutlined className="component-icons" />
        <div className="component-title">Recogidas</div>
        <br />
        <br />
        <Row gutter={24}>
          <Col span={12}>
            <div style={{ fontSize: 'medium' }}>
              Creados desde:
              <DatePicker
                style={{ marginLeft: '1em', marginRight: '1em' }}
                onChange={(date) => this.setState({ since: moment(date).startOf('day') })}
                format="YYYY-MM-DD"
                value={this.state.since}
              />
              (12 am)
            </div>
          </Col>
          <Col span={12}>
            <div style={{ fontSize: 'medium' }}>
              Creados hasta:
              <DatePicker
                style={{ marginLeft: '1em', marginRight: '1em' }}
                onChange={(date) => this.setState({ to: moment(date).endOf('day') })}
                format="YYYY-MM-DD"
                value={this.state.to}
              />
              (12 pm)
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <Row gutter={24}>
          {this.props.role === 'ADMIN_USER' ? (
            <Col span={12}>
              <div style={{ fontSize: 'medium' }}>Buscar por id propietario:</div>
              <Input
                value={this.state.ownerId}
                onChange={(e) => this.setState({ ownerId: e.target.value })}
                style={{ width: 400 }}
              />
            </Col>
          ) : (
            <div />
          )}

          <Col span={this.props.role === 'ADMIN_USER' ? 12 : 24}>
            <div style={{ fontSize: 'medium' }}>Buscar por numero de guía:</div>
            <Input
              value={this.state.trackingNumber}
              onChange={(e) => this.setState({ trackingNumber: e.target.value })}
              style={{ width: 400 }}
            />
          </Col>
        </Row>
        {this.state.loading && <LoadingOutlined className="component-icons" />}
        <br />
        <br />
        <Button type="primary" onClick={this.reload}>
          Buscar
        </Button>
        <br />
        <br />
        <br />
        <GeneralInfoTable
          fetch={this.fetchShipments}
          pagination={pagination}
          pickups={pickups}
          reload={this.reload}
          loading={loading}
        />
        <Errors errorStatus={this.state.errorStatus} />
      </div>
    );
  }
}

PickUp.propTypes = {
  role: PropTypes.string,
};

export default PickUp;
