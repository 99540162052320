import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Sorry from './Sorry';
import DisplayErrorMessage from './DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');
class Errors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshTokenExpired: false,
    };
  }

  refreshToken() {
    if (this.props.errorStatus === 401) {
      const refreshToken = ls.get('refreshTokenAdmin');
      const config = {
        method: 'post',
        data: { refreshToken },
        url: `${ls.get('gatewayAPI')}/identity-service-api/auth/renew-token`,
      };
      axios(config)
        .then((result) => {
          ls.set('authTokenAdmin', result.data.accessToken);
          window.location.reload();
        })
        .catch((e) => {
          if (e.response.status === 403) this.setState({ refreshTokenExpired: true });
          else DisplayErrorMessage(e);
        });
    }
  }

  render() {
    this.refreshToken();
    const invalidToken = this.props.errorStatus === 403;
    return (
      <div>
        {(invalidToken || this.state.refreshTokenExpired) && <Redirect push to="/cerrar" />}
        {this.props.errorStatus > 499 && <Sorry />}
      </div>
    );
  }
}

Errors.propTypes = {
  errorStatus: PropTypes.number,
};

export default Errors;
