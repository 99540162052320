import displayErrorMessage from '../components/Utils/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const saveClient = async (client) => {
  const {
    alias,
    name,
    lastName,
    phone,
    email,
    docType,
    docNumber,
    street1,
    street2,
    indications,
    country,
    postalCode,
    ownerId,
    cityCode,
  } = client;
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/client`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  const data = {
    client: {
      alias,
      name,
      lastName,
      phone,
      email,
      docType,
      docNumber,
      street1,
      street2,
      indications,
      country,
      postalCode,
      ownerId,
      cityCode,
    },
  };
  try {
    const result = await axios({ method: 'post', url, headers, data });
    return result.data;
  } catch (e) {
    displayErrorMessage(e, 'Error creando el cliente');
  }
  return undefined;
};

export const updateClient = async (id, client) => {
  const {
    alias,
    name,
    lastName,
    phone,
    email,
    docType,
    docNumber,
    street1,
    street2,
    indications,
    country,
    postalCode,
    ownerId,
    cityCode,
  } = client;
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/client/${id}`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  const data = {
    alias,
    name,
    lastName,
    phone,
    email,
    docType,
    docNumber,
    street1,
    street2,
    indications,
    country,
    postalCode,
    ownerId,
    cityCode,
  };
  try {
    const result = await axios({ method: 'patch', url, headers, data });
    return result;
  } catch (e) {
    displayErrorMessage(e, 'Error creando el cliente');
  }
  return undefined;
};

export const fetchClients = async (
  page,
  pageSize,
  docNumber,
  alias,
  email,
  ownerId,
  name,
  lastName,
) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api/client?page=${
    page - 1
  }&pageSize=${pageSize}`;
  if (ownerId) url += `&ownerId=${ownerId}`;
  if (docNumber) url += `&docNumber=${docNumber}`;
  if (alias) url += `&alias=${alias}`;
  if (email) url += `&email=${email}`;
  if (name) url += `&name=${name}`;
  if (lastName) url += `&lastName=${lastName}`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const totalPages = parseInt(result.headers['pagination-total'], 10);
    return { clients: result.data, totalPages };
  } catch (e) {
    displayErrorMessage(e, `Error: ${e.message}`);
  }
  return { clients: [], totalPages: 0 };
};

export const deleteClients = async (id) => {
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/client?id=${id}`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };

  try {
    const result = await axios({ method: 'delete', url, headers });

    return result.data;
  } catch (e) {
    displayErrorMessage(e, 'Error eliminado al cliente');
  }
  return undefined;
};
