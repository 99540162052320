import displayErrorMessage from '../components/Utils/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

const PUBLIC_ENDPOINTS = '/shipping-service-api/public/';

export const fetchShippingCarriers = async () => {
  const url = `${ls.get('gatewayAPI')}${PUBLIC_ENDPOINTS}carriers`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const shippingCarriers = [];
    result.data.forEach((shippingCarrier) => {
      shippingCarriers.push(shippingCarrier);
    });
    return { shippingCarriers };
  } catch (e) {
    displayErrorMessage(e, 'Error recuperando transportadoras');
  }
  return [];
};

export const fetchPaymentTypes = async () => {
  const url = `${ls.get('gatewayAPI')}/shipping-service-api/public/paymenttypes`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    return { paymentTypes: result.data };
  } catch (e) {
    displayErrorMessage(e, 'Error recuperando medios de pago');
  }
  return [];
};

export const fetchShipment = async (
  page,
  pageSize,
  trackingNumber,
  externalId,
  ownerId,
  createdAfter,
  createdBefore,
  selectedShippingCarriers,
  selectedPaymentTypes,
  selectedShipmentStatuses,
  shipmentId,
) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api/shipment?page=${
    page - 1
  }&pageSize=${pageSize}`;
  if (ownerId) url += `&ownerId=${ownerId}`;
  if (trackingNumber) url += `&trackingNumber=${trackingNumber}`;
  if (externalId) url += `&externalId=${externalId}`;
  if (selectedShippingCarriers.length > 0)
    url += `&shippingCarriers=${JSON.stringify(selectedShippingCarriers)}`;
  if (selectedPaymentTypes.length > 0)
    url += `&paymentTypes=${JSON.stringify(selectedPaymentTypes)}`;
  if (selectedShipmentStatuses.length > 0)
    url += `&shipmentStatuses=${JSON.stringify(selectedShipmentStatuses)}`;
  if (createdAfter) url += `&createdAfter=${createdAfter.toISOString()}`;
  if (createdBefore) url += `&createdBefore=${createdBefore.toISOString()}`;
  if (shipmentId) url += `&shipmentId=${shipmentId}`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}`, 'owner-id': ownerId };
  try {
    const result = await axios({ method: 'get', url, headers });
    const totalPages = parseInt(result.headers['pagination-total'], 10);
    return { shipments: result.data, totalPages };
  } catch (e) {
    displayErrorMessage(e, 'Error recuperando envios');
  }
  return { shipments: [], totalPages: 0 };
};

export const fetchPickUps = async (
  pagination,
  trackingNumber,
  ownerId,
  createdAfter,
  createdBefore,
) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api/shipment?page=${
    pagination.current - 1
  }&pageSize=${pagination.pageSize}`;
  if (ownerId) url += `&ownerId=${ownerId}`;
  if (trackingNumber) url += `&trackingNumber=${trackingNumber}`;
  if (createdAfter) url += `&createdAfter=${createdAfter.toISOString()}`;
  if (createdBefore) url += `&createdBefore=${createdBefore.toISOString()}`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}`, 'owner-id': ownerId };
  try {
    const result = await axios({ method: 'get', url, headers });
    const totalPages = parseInt(result.headers['Pagination-Total'], 10);
    return { shipments: result.data, totalPages };
  } catch (e) {
    displayErrorMessage(e, 'Error recuperando recogidas');
  }
  return { shipments: [], totalPages: 0 };
};

export const getShipment = async (shipmentId) => {
  let url = `${ls.get('gatewayAPI')}/shipping-service-api/public`;
  url += `/shipment/${shipmentId}`;
  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    const shipment = result.data;
    return shipment;
  } catch (e) {
    displayErrorMessage(e, 'Error recuperando guía');
  }
  return undefined;
};
