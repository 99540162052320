import React from 'react';
import PropTypes from 'prop-types';
import { PrinterOutlined } from '@ant-design/icons';
import { Col, Table, Row } from 'antd';
import moment from 'moment';
import { getShipment } from '../../gateway/fetchShipment';
import { sortEventsByDate, sortStatusHistoryByDate, toCurrency } from '../Utils/formatters';
import { fetchCities } from '../../gateway/companyGateway';

class Shipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boxesPerShipment: [],
      statusHistory: [],
      shipment: {},
      senderCity: '',
      receiverCity: '',
    };
  }

  setColumns() {
    const boxesColumns = [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Ancho (cm)',
        dataIndex: 'width',
        key: 'width',
      },
      {
        title: 'Largo (cm)',
        dataIndex: 'length',
        key: 'length',
      },
      {
        title: 'Alto (cm)',
        dataIndex: 'height',
        key: 'height',
      },
      {
        title: 'Unidades',
        dataIndex: 'units',
        key: 'units',
      },
    ];
    const statusColumns = [
      {
        title: 'Creado en',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: 'Estado Valienta',
        dataIndex: `valientaStatus`,
        key: 'valientaStatus',
      },
      {
        title: 'Estado transportadora',
        dataIndex: 'carrierStatus',
        key: 'carrierStatus',
      },
      {
        title: 'Detalles',
        dataIndex: 'lastEvent',
        key: 'lastEvent',
      },
    ];
    this.setState({ boxesColumns, statusColumns });
  }

  async formatStatusHistory(shipment) {
    let sortedStatusHistory = [];
    sortedStatusHistory = sortStatusHistoryByDate(shipment.statusHistory);

    const status = sortedStatusHistory.map((element) => {
      const { id, createdAt, details } = element;
      const valientaStatus = element.valientaStatus.value;
      const carrierStatus = element.shipmentCarrierStatus?.value;
      const sortedEvents = details ? sortEventsByDate(details) : undefined;
      let lastEvent;
      if (sortedEvents) {
        lastEvent = details[details.length - 1].eventName;
      }

      return { id, createdAt, valientaStatus, carrierStatus, lastEvent };
    });

    this.setState({ statusHistory: status });
  }

  async fetchCities() {
    const senderCity = Object.values(
      await fetchCities(undefined, this.props.shipment.sender.cityCode),
    )[0];
    const receiverCity = Object.values(
      await fetchCities(undefined, this.props.shipment.receiver.cityCode),
    )[0];

    this.setState({ senderCity, receiverCity });
  }

  async componentDidMount() {
    await this.fetchCities();
    await this.fetchShipmentInfo();
    this.setColumns();
  }

  async fetchShipmentInfo() {
    if (this.props.shipment.id) {
      const shipment = await getShipment(this.props.shipment.id);
      await this.formatStatusHistory(shipment);
      this.setState({
        shipment,
        boxesPerShipment: shipment.packages,
      });
    }
  }

  render() {
    const { boxesColumns, boxesPerShipment, statusColumns, statusHistory, shipment } = this.state;
    return (
      <div>
        <br />
        {shipment && (
          <div className="row no-padding">
            <div className="col no-padding">
              <h3>Datos del envío</h3>
              <div className="row no-padding">
                <div className="col no-padding">
                  {this.props.shipment && this.props.shipment.shipmentId && (
                    <div className="row no-padding">
                      {`Id envío: ${this.props.shipment.shipmentId || ''}`}
                    </div>
                  )}
                  {shipment.pickupNumber && (
                    <div className="row no-padding">
                      {`Pickup number: ${shipment.pickupNumber}`}
                    </div>
                  )}
                  {shipment.shippingCarrier && (
                    <div className="row no-padding">
                      {`Empresa de envíos: ${shipment.shippingCarrier.name}`}
                    </div>
                  )}
                  {shipment.trackingNumber && (
                    <div className="row no-padding">
                      Número de rastreo:{' '}
                      <strong style={{ marginLeft: '5px' }}>{shipment.trackingNumber}</strong>
                    </div>
                  )}
                  {shipment.currentStatus && (
                    <div className="row no-padding">
                      {`Estado: ${shipment.currentStatus.value}`}
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <h4>Costos de envío</h4>
              <div className="row no-padding">
                <div className="col no-padding">
                  {this.props.shipment.shippingCostChargedByValienta && (
                    <div className="row no-padding">
                      Cobro de Valienta por envío:{' '}
                      {toCurrency(this.props.shipment.shippingCostChargedByValienta)}
                    </div>
                  )}
                  {shipment.costFromShippingCarrier && (
                    <div className="row no-padding">
                      Costo de empresa por envío: {toCurrency(shipment.costFromShippingCarrier)}
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <h4>Creación y entrega</h4>
              <div className="row no-padding">
                <div className="col no-padding">
                  <div className="row no-padding">
                    {shipment.createdAt &&
                      `Fecha de creación: ${moment(shipment.createdAt).format('DD-MM-YYYY')}`}
                  </div>
                  {shipment.pickup && (
                    <div className="row no-padding">
                      {`Fecha programada: ${moment(shipment.pickup.scheduledAt).format(
                        'DD-MM-YYYY',
                      )}`}
                    </div>
                  )}
                  {shipment.pickup && (
                    <div className="row no-padding">
                      {`Número de recolección: ${shipment.pickup.pickupNumber}`}
                    </div>
                  )}
                  {shipment.paymentType && (
                    <div className="row no-padding">
                      {`Método de pago: ${shipment.paymentType.value}`}
                    </div>
                  )}
                </div>
              </div>
              <hr />
              <h3>Datos de contacto</h3>
              <div>
                {shipment.sender && shipment.receiver && (
                  <Row>
                    <Col span={12}>
                      <h4>
                        <b>ENVIA</b>
                      </h4>
                      <p>Nombre: {shipment.sender.name}</p>
                      <p>Cedula: {shipment.sender.docNumber}</p>
                      <p>Ciudad {this.state.senderCity.label}</p>
                      <p>
                        Dirección: {shipment.sender.street1} - {shipment.sender?.street2}
                      </p>
                    </Col>
                    <Col span={12}>
                      <h4>
                        <b>RECIBE</b>
                      </h4>
                      <p>Nombre: {shipment.receiver.name}</p>
                      <p>Cedula: {shipment.receiver.docNumber}</p>
                      <p>Ciudad: {this.state.receiverCity.label}</p>
                      <p>
                        Dirección: {shipment.receiver.street1} - {shipment.receiver?.street2}
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
              {shipment.trackingPdf && (
                <div className="col no-padding" style={{ marginLeft: '0', marginTop: '10px' }}>
                  <button
                    type="button"
                    className="button primary small"
                    onClick={() => window.open(shipment.trackingPdf)}
                  >
                    <PrinterOutlined style={{ marginRight: '5px' }} />
                    Imprimir guía
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <br />
        <h3>Cajas del envio:</h3>
        <Table rowKey={(b) => b.id} columns={boxesColumns} dataSource={boxesPerShipment} />
        <br />
        <h3>Historial:</h3>
        <Table rowKey={(s) => s.id} columns={statusColumns} dataSource={statusHistory} />
      </div>
    );
  }
}

Shipment.propTypes = {
  shipment: PropTypes.object,
};

export default Shipment;
