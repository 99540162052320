import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Tooltip, Row, Col } from 'antd';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import Shipment from './Shipment';

class GeneralInfoTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fetchShipments = this.fetchShipments.bind(this);
  }

  getCols() {
    return [
      {
        title: 'Fecha creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => {
          const d = moment(new Date(text));
          d.format('YYYY/MM/DD hh:mm a');
          return <div>{d.format('YYYY/MM/DD hh:mm a')}</div>;
        },
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Número de guía',
        dataIndex: '',
        render: (s) => <div>{s.trackingNumber || '-'}</div>,
        key: 'trackingNumber',
      },
      {
        title: 'Id Envío',
        dataIndex: '',
        render: (s) => <div>{s.id}</div>,
        key: 'id',
      },
      {
        title: 'Id Externo',
        dataIndex: '',
        render: (s) => <div>{s.externalId}</div>,
        key: 'externalId',
      },
      {
        title: 'Tipo de pago',
        dataIndex: '',
        render: (s) => <div>{s.paymentType.value}</div>,
        key: 'paymentType.id',
      },
      {
        title: 'Transportadora',
        dataIndex: '',
        render: (s) => <div>{s.shippingCarrier.name}</div>,
        key: 'shippingCarrier.id',
      },
      {
        title: 'Estado',
        dataIndex: '',
        render: (s) => <div>{s.currentStatus.value}</div>,
        key: 'currentStatus.id',
      },
      {
        title: 'Acciones',
        dataIndex: '',
        render: (s) => (
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <Tooltip title="Cancelar envío">
                  <Button
                    disabled={s.currentStatus.value === 'SHIPMENT CANCELED'}
                    type="primary"
                    icon={<CloseOutlined />}
                    danger
                    onClick={() => this.props.cancelShipment(s.id)}
                  />
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
        key: 'id',
      },
    ];
  }

  fetchShipments(pagination) {
    this.props.fetch(pagination);
  }

  render() {
    return (
      <div style={{ paddingLeft: '1%', paddingRight: '1%' }}>
        {this.props.shipments && (
          <Table
            scroll={{ x: 'max-content' }}
            rowKey={(shipment) => shipment.id}
            columns={this.getCols()}
            dataSource={this.props.shipments}
            pagination={this.props.pagination}
            onChange={this.fetchShipments}
            loading={this.props.loading}
            expandedRowRender={(s) => <Shipment shipment={s} />}
          />
        )}
      </div>
    );
  }
}

GeneralInfoTable.propTypes = {
  shipments: PropTypes.any,
  fetch: PropTypes.func,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
  cancelShipment: PropTypes.func,
  /* openPickUpModal: PropTypes.func, */
};

export default GeneralInfoTable;
