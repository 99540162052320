import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, DatePicker, Row, Col, Input, Select, notification, Modal } from 'antd';
import { ReconciliationOutlined, LoadingOutlined } from '@ant-design/icons';
import GeneralInfoTable from './GeneralInfoTable';
import Errors from '../Utils/Errors/Errors';
import {
  fetchShippingCarriers,
  fetchShipment,
  fetchPaymentTypes,
} from '../../gateway/fetchShipment';
import { cancelShipment /* schedulePickUp */ } from '../../gateway/createShipment';

const { Option } = Select;

class Shipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: { current: 1, pageSize: 10 },
      shipments: [],
      since: moment(new Date().setDate(new Date().getDate() - 1)),
      to: moment().endOf('day'),
      scheduledDate: moment(new Date().setDate(new Date().getDate() + 5)),
      shippingCarriers: [],
      paymentTypes: [],
      shipmentStatuses: [
        { name: 'SHIPMENT CREATED' },
        { name: 'GUIDE CREATED' },
        { name: 'PICKUP SCHEDULED' },
        { name: 'SHIPMENT IN TRANSIT' },
        { name: 'SHIPMENT DELIVERED' },
        { name: 'SHIPMENT FAILED' },
        { name: 'SHIPMENT CANCELED' },
        { name: 'SHIPMENT ALERT' },
      ],
      selectedShippingCarriers: [],
      selectedPaymentTypes: [],
      selectedShipmentStatuses: [],
      trackingNumber: undefined,
      externalId: undefined,
      ownerId: undefined,
      fetchingShippingCarriers: false,
      fetchingShipmentStatuses: false,
      fetchingPaymentTypes: false,
      loading: false,
      isModalVisible: false,
    };
    this.reload = this.reload.bind(this);
    this.fetchShipments = this.fetchShipments.bind(this);
    this.fetchShippingCarriers = this.fetchShippingCarriers.bind(this);
    this.fetchPaymentTypes = this.fetchPaymentTypes.bind(this);
    this.cancelShipment = this.cancelShipment.bind(this);
    this.openPickUpModal = this.openPickUpModal.bind(this);
    this.schedulePickUp = this.schedulePickUp.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  reload() {
    this.setState(
      {
        pagination: { current: 1, pageSize: 10 },
        shipments: [],
      },
      () => this.fetchShipments(this.state.pagination),
    );
  }

  async fetchPaymentTypes() {
    this.setState({ fetchingPaymentTypes: true });
    const { paymentTypes } = await fetchPaymentTypes();
    this.setState({ paymentTypes, fetchingPaymentTypes: false });
  }

  async fetchShipments(params) {
    const { current, pageSize } = params;
    this.setState({ loading: true, shipments: [] });
    const {
      trackingNumber,
      externalId,
      ownerId,
      since,
      to,
      selectedShippingCarriers,
      selectedPaymentTypes,
      selectedShipmentStatuses,
      shipmentId,
    } = this.state;
    if (shipmentId) {
      const { shipments } = await fetchShipment(
        current,
        pageSize,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        [],
        [],
        [],
        shipmentId,
      );
      this.setState({ loading: false, shipments });
    } else {
      const { shipments, totalPages } = await fetchShipment(
        current,
        pageSize,
        trackingNumber,
        externalId,
        ownerId,
        since,
        to,
        selectedShippingCarriers,
        selectedPaymentTypes,
        selectedShipmentStatuses,
      );
      const pagination = { ...params, total: totalPages };
      this.setState({
        loading: false,
        shipments,
        pagination,
      });
    }
  }

  async fetchShippingCarriers() {
    this.setState({ fetchingShippingCarriers: true });
    const { shippingCarriers } = await fetchShippingCarriers();
    this.setState({ shippingCarriers, fetchingShippingCarriers: false });
  }

  async cancelShipment(id) {
    const { message } = await cancelShipment(id);
    if (message === 'OK') {
      notification.success({
        message,
        description: `Id: ${id} fue cancelado`,
        duration: 5,
      });
      this.reload();
    } else {
      notification.error({
        message: 'Error',
        description: `Id: ${id} no se pudo cancelar`,
        duration: 5,
      });
    }
  }

  async openPickUpModal(shipmentId) {
    this.setState({ isModalVisible: true, pickUpShipmentId: shipmentId });
  }

  async schedulePickUp() {
    /* eslint-disable no-console */
    console.log(this.state.pickUpShipmentId);
    /* eslint-enable no-console */
    /* const { message } = await this.schedulePickUp(
      this.state.pickUpShipmentId,
      this.state.scheduledDate,
    );
    notification.success({
      message,
      description: 'Recogida programada',
      duration: 5,
    }); */
  }

  handleCancel() {
    this.setState({ isModalVisible: false });
  }

  componentDidMount() {
    this.fetchShipments(this.state.pagination);
    this.fetchShippingCarriers();
    this.fetchPaymentTypes();
  }

  render() {
    return (
      <div>
        <Modal
          title="Seleccione"
          visible={this.state.isModalVisible}
          onOk={this.schedulePickUp}
          onCancel={this.handleCancel}
        >
          <div style={{ fontSize: 'medium' }}>
            Fecha para recogida:
            <DatePicker
              style={{ marginLeft: '1em', marginRight: '1em' }}
              onChange={(date) => this.setState({ scheduledDate: moment(date).startOf('day') })}
              format="YYYY-MM-DD"
              value={this.state.scheduledDate}
            />
          </div>
        </Modal>
        <ReconciliationOutlined className="component-icons" />
        <div className="component-title">Envíos</div>
        <br />
        <br />
        <Row gutter={24}>
          <Col span={12}>
            <div style={{ fontSize: 'medium' }}>
              Creados desde:
              <DatePicker
                style={{ marginLeft: '1em', marginRight: '1em' }}
                onChange={(date) => this.setState({ since: moment(date).startOf('day') })}
                format="YYYY-MM-DD"
                value={this.state.since}
              />
              (12 am)
            </div>
          </Col>
          <Col span={12}>
            <div style={{ fontSize: 'medium' }}>
              Creados hasta:
              <DatePicker
                style={{ marginLeft: '1em', marginRight: '1em' }}
                onChange={(date) => this.setState({ to: moment(date).endOf('day') })}
                format="YYYY-MM-DD"
                value={this.state.to}
              />
              (12 pm)
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Row gutter={24}>
          <Col span={4}>
            <div style={{ fontSize: 'medium' }}>Número de guía:</div>
            <Input
              value={this.state.trackingNumber}
              onChange={(e) => this.setState({ trackingNumber: e.target.value })}
              style={{ width: 200 }}
            />
          </Col>
          <Col span={4}>
            <div style={{ fontSize: 'medium' }}>Id externo:</div>
            <Input
              value={this.state.externalId}
              onChange={(e) => this.setState({ externalId: e.target.value })}
              style={{ width: 200 }}
            />
          </Col>
          {this.props.role === 'ADMIN_USER' ? (
            <Col span={4}>
              <div style={{ fontSize: 'medium' }}>Id propietario:</div>
              <Input
                value={this.state.ownerId}
                onChange={(e) => this.setState({ ownerId: e.target.value })}
                style={{ width: 200 }}
              />
            </Col>
          ) : (
            <div />
          )}

          <Col span={4}>
            <div style={{ fontSize: 'medium' }}>Transportadora:</div>
            <Select
              mode="multiple"
              placeholder="Seleccione"
              allowClear
              style={{ width: 200 }}
              loading={this.state.fetchingShippingCarriers}
              filterOption={false}
              onChange={(e) => this.setState({ selectedShippingCarriers: e })}
            >
              {this.state.shippingCarriers.map((shippingCarrier) => (
                <Option key={shippingCarrier.id}>{shippingCarrier.name}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <div style={{ fontSize: 'medium' }}>Tipo de pago:</div>
            <Select
              mode="multiple"
              placeholder="Seleccione"
              allowClear
              style={{ width: 200 }}
              loading={this.state.fetchingPaymentTypes}
              filterOption={false}
              onChange={(e) => this.setState({ selectedPaymentTypes: e })}
            >
              {this.state.paymentTypes.map((paymentType) => (
                <Option key={paymentType.id}>{paymentType.value}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <div style={{ fontSize: 'medium' }}>Estado de envío:</div>
            <Select
              mode="multiple"
              placeholder="Seleccione"
              allowClear
              style={{ width: 200 }}
              loading={this.state.fetchingShipmentStatuses}
              filterOption={false}
              onChange={(e) => this.setState({ selectedShipmentStatuses: e })}
            >
              {this.state.shipmentStatuses.map((shipmentStatus) => (
                <Option key={shipmentStatus.name}>{shipmentStatus.name}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <br />
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <div style={{ fontSize: 'medium' }}>Buscar por Id:</div>
            <Input
              value={this.state.shipmentId}
              onChange={(e) => this.setState({ shipmentId: e.target.value })}
              style={{ width: 400 }}
            />
          </Col>
        </Row>
        {this.state.loading && <LoadingOutlined className="component-icons" />}
        <br />
        <br />
        <Button type="primary" onClick={this.reload}>
          Buscar
        </Button>
        <br />
        <br />
        <div className="row">
          <h3 style={{ padding: '0 20px' }}>
            Total paquetes encontrados: {this.state.pagination.total || 0}
          </h3>
        </div>
        <br />
        <GeneralInfoTable
          fetch={this.fetchShipments}
          pagination={this.state.pagination}
          shipments={this.state.shipments}
          reload={this.reload}
          loading={this.state.loading}
          cancelShipment={this.cancelShipment}
        />
        <Errors errorStatus={this.state.errorStatus} />
      </div>
    );
  }
}

Shipment.propTypes = {
  role: PropTypes.string,
};

export default Shipment;
