import React from 'react';

class Sorry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ padding: '5%' }}>
        Lo sentimos mucho!
        <br />
        <br />
        No pudimos encontrar lo que buscabas.
        <br />
        Recarga la pagina para intentar de nuevo.
      </div>
    );
  }
}

export default Sorry;
