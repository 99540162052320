import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import moment from 'moment';
/* import { toCurrency } from '../../utils/formatters'; */
class GeneralInfoTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCols() {
    return [
      {
        title: 'Fecha creación',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => {
          const d = moment(new Date(text));
          d.format('YYYY/MM/DD hh:mm a');
          return <div>{d.format('YYYY/MM/DD hh:mm a')}</div>;
        },
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Fecha recogida',
        dataIndex: 'scheduledPickupDate',
        key: 'scheduledPickupDate',
        render: (text) => {
          const d = moment(new Date(text));
          d.format('YYYY/MM/DD hh:mm a');
          return <div>{d.format('YYYY/MM/DD hh:mm a')}</div>;
        },
        sorter: (a, b) => new Date(a.pickup?.scheduledAt) - new Date(b.pickup?.scheduledAt),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Número de pickup',
        dataIndex: '',
        render: (p) => <div>{p.pickup?.pickupNumber || '-'}</div>,
        key: 'pickupNumber',
      },
      {
        title: 'Calle 1',
        dataIndex: '',
        render: (p) => <div>{p.pickup?.street1}</div>,
        key: 'street1',
      },
      {
        title: 'Calle 2',
        dataIndex: '',
        render: (p) => <div>{p.pickup?.street2}</div>,
        key: 'street2',
      },
      {
        title: 'Codigo ciudad',
        dataIndex: '',
        render: (p) => <div>{p.pickup?.cityCode}</div>,
        key: 'cityCode',
      },
    ];
  }

  render() {
    return (
      <div style={{ paddingLeft: '1%', paddingRight: '1%' }}>
        {this.props.pickups && (
          <Table
            scroll={{ x: 'max-content' }}
            rowKey={(pickup) => pickup.id}
            columns={this.getCols()}
            dataSource={this.props.pickups}
            pagination={this.props.pagination}
            onChange={this.props.fetch}
            loading={this.props.loading}
          />
        )}
      </div>
    );
  }
}

GeneralInfoTable.propTypes = {
  pickups: PropTypes.any,
  fetch: PropTypes.func,
  pagination: PropTypes.object,
  loading: PropTypes.bool,
};

export default GeneralInfoTable;
