import React from 'react';
import PropTypes from 'prop-types';
import WebView from './WebView';
import './Login.css';
import DisplayErrorMessage from '../Utils/Errors/DisplayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.verifyTokens = this.verifyTokens.bind(this);
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams) {
      this.verifyTokens(queryParams);
    } else if (ls.get('authToken') && ls.get('refreshToken')) {
      this.props.login(ls.get('authToken'), ls.get('refreshToken'));
    }
  }

  async verifyTokens(queryParams) {
    const authToken = queryParams.get('authToken');
    const refreshToken = queryParams.get('refreshToken');
    if (authToken && refreshToken) {
      const url = `${ls.get('gatewayAPI')}/identity-service-api/auth/validate-token`;
      const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
      const data = { authToken };
      try {
        const result = await axios({ method: 'post', url, headers, data });
        if (result.status === 200) {
          this.props.login(authToken, refreshToken, result.data.roles[0]);
        } else {
          this.DisplayErrorMessage('Token inválido o vencido');
        }
      } catch (e) {
        DisplayErrorMessage(e.response.data.message);
      }
    }
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <WebView login={this.props.login} />
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func,
};

export default Login;
