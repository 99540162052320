import React from 'react';
import NumberFormat from 'react-number-format';

export function toCurrency(value, colorR) {
  const color = colorR || 'black';
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator
      style={{ color }}
      prefix=" $ "
    />
  );
}

export function sortEventsByDate(eventsDTO) {
  return eventsDTO.sort((a, b) => +new Date(a.eventDate) - new Date(+b.eventDate));
}

export function sortStatusHistoryByDate(statusHistory) {
  return statusHistory.sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));
}
