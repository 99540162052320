import React from 'react';
import { Form, Button, Table, Row, AutoComplete } from 'antd';
import { GatewayOutlined } from '@ant-design/icons';
import './ValidateRoute.css';
import { validateRoute } from '../../gateway/createShipment';
import { fetchCities } from '../../gateway/companyGateway';

const columns = [
  {
    title: 'Transportadora',
    dataIndex: 'carrier',
    key: 'carrier',
  },
  {
    title: 'Pre Pago',
    dataIndex: 'prePaid',
    key: 'prePaid',
  },
  {
    title: 'Contra Entrega',
    dataIndex: 'onDelivery',
    key: 'onDelivery',
  },
];

class ValidateRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      fetchingCities: false,
      carriers: [],
      loading: false,
    };
    this.fetchCities = this.fetchCities.bind(this);
  }

  async fetchCities(cityName) {
    this.setState({ fetchingCities: true });
    const cities = await fetchCities(cityName);
    this.setState({ cities, fetchingCities: false });
  }

  componentDidMount() {
    this.fetchCities('');
  }

  handleSearch = (value) => {
    this.fetchCities(value);
  };

  render() {
    const onFinish = async (e) => {
      this.setState({ loading: true });
      const result = await validateRoute(e.Origen, e.Destino);
      const { carriersAvailable } = result.message;
      const { carriersWithOnDeliveryPayment } = result.message;

      const carriers = carriersAvailable.map((c) => {
        const carrier = {
          key: c,
          carrier: c,
          prePaid: 'Si',
          onDelivery: carriersWithOnDeliveryPayment.includes(c) ? 'Si' : 'No',
        };
        return carrier;
      });
      this.setState({ carriers, loading: false });
    };

    return (
      <div>
        <GatewayOutlined className="component-icons" />
        <div className="component-title">Ruta</div>
        <br />
        <div className="container">
          <Row>
            <div className="input-route">
              <Form className="form" layout="inline" onFinish={onFinish}>
                <Form.Item label="Origen" name="Origen">
                  <AutoComplete
                    style={{ width: 155 }}
                    options={this.state.cities}
                    onSearch={this.handleSearch}
                    filterOption={(inputValue, option) =>
                      option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    loading={this.state.fetchingCities}
                  />
                </Form.Item>
                <Form.Item label="Destino" name="Destino">
                  <AutoComplete
                    style={{ width: 155 }}
                    options={this.state.cities}
                    onSearch={this.handleSearch}
                    filterOption={(inputValue, option) =>
                      option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    loading={this.state.fetchingCities}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Validar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Row>
        </div>
        <div>
          <Table columns={columns} dataSource={this.state.carriers} loading={this.state.loading} />
        </div>
      </div>
    );
  }
}

export default ValidateRoute;
