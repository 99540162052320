import React from 'react';
import { Layout, Menu } from 'antd';
import {
  CloseOutlined,
  FormOutlined,
  CarOutlined,
  ReconciliationOutlined,
  GatewayOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './SideNav.css';

const ls = require('local-storage');
const { Sider } = Layout;

class SideNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!ls.get('authToken')) {
      return <div />;
    }
    return (
      <Layout>
        <Sider className="SideNav-sider">
          <div className="logo" />
          <Menu theme="dark" mode="inline" defaultSelectedKeys="queries">
            <Menu.Item key="quote">
              <Link to="/creacion-envios">
                <FormOutlined />
                <span>Cotizar/Crear envíos</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="shipment">
              <Link to="/consulta-envios">
                <ReconciliationOutlined />
                <span>Consulta Envíos</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="pickup">
              <Link to="/recogidas">
                <CarOutlined />
                <span>Recogidas</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="route">
              <Link to="/validar-ruta">
                <GatewayOutlined />
                <span>Validar Ruta</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="createclient">
              <Link to="/clientes">
                <GatewayOutlined />
                <span>Crear Clientes</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="getclient">
              <Link to="/consultar-clientes">
                <GatewayOutlined />
                <span>Consultar Clientes</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="logout">
              <Link to="/cerrar">
                <CloseOutlined />
                <span>Cerrar sesión</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ background: 'white', textAlign: 'center', color: 'black' }}>
          {this.props.component}
        </Layout>
      </Layout>
    );
  }
}

SideNavbar.propTypes = {
  component: PropTypes.any,
};

export default SideNavbar;
