import React from 'react';
import { Input, Button, Col, Divider, Row, notification } from 'antd';
import { LoadingOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const axios = require('axios');
const ls = require('local-storage');

class WebView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.login = this.login.bind(this);
  }

  displayError(errorMessage) {
    let message = errorMessage;
    if (errorMessage.includes('User with email')) {
      message = 'El correo ingresado no correspone a una cuenta registrada';
    } else if (errorMessage.includes('Incorrect password')) {
      message = 'Contraseña incorrecta';
    }
    notification.error({
      message: `Ocurrio un problema`,
      duration: 10,
      description: `${message}`,
    });
  }

  async login() {
    this.setState({ loading: true });
    const { password } = this.state;
    const data = { email: this.state.email, password };
    const url = `${ls.get('gatewayAPI')}/identity-service-api/auth/login`;
    const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
    try {
      const result = await axios({ method: 'post', url, headers, data });
      this.props.login(result.data.accessToken, result.data.refreshToken);
    } catch (e) {
      this.displayError(e.response.data.message);
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <Row>
        <Col
          span={12}
          style={{
            height: window.innerHeight,
            width: '45%',
          }}
        >
          <div style={{ marginTop: '20%' }}>
            <div
              style={{
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                fontSize: '30px',
                textAlign: 'left',
                lineHeight: '35px',
                marginLeft: '25%',
                color: '#23293D',
              }}
            >
              Iniciar sesión :D
            </div>
            <div style={{ width: '27%', marginLeft: '25%' }}>
              <Divider style={{ borderTop: '1.5px solid #F07196' }} />
            </div>
            <br />
            <div
              style={{
                fontFamily: 'Roboto',
                textAlign: 'left',
                marginLeft: '25%',
                fontSize: '1.2em',
                color: '#23293D',
              }}
            >
              Correo electrónico
            </div>
            <br />
            <Input
              placeholder="Ingresa tu Correo"
              onChange={(e) => this.setState({ email: e.target.value })}
              style={{
                width: '50%',
                height: '2em',
                fontSize: '1.5em',
                borderRadius: '0.5em',
              }}
              prefix={<MailOutlined />}
            />
            <br />
            <br />
            <div
              style={{
                fontFamily: 'Roboto',
                textAlign: 'left',
                marginLeft: '25%',
                color: '#23293D',
                fontSize: '1.2em',
              }}
            >
              Contraseña
            </div>
            <br />
            <Input.Password
              placeholder="Ingresa tu contrasena"
              onChange={(e) => this.setState({ password: e.target.value })}
              style={{
                width: '50%',
                height: '2em',
                fontSize: '1.5em',
                borderRadius: '0.5em',
              }}
              prefix={<LockOutlined />}
            />
            <br />
            <br />
            <br />
            <Button
              type="primary"
              onClick={this.login}
              style={{
                width: '50%',
                height: '2em',
                fontSize: '1.5em',
                background: 'linear-gradient(180deg, #242B41 0%, #39487D 100%), #FFFFFF',
                border: 'none',
                borderRadius: '10px',
              }}
            >
              <div style={{ color: 'white', fontSize: '0.7em' }}>
                {!this.state.loading && 'Ingresar'}
                {this.state.loading && <LoadingOutlined />}
              </div>
            </Button>
            <br />
            <br />
          </div>
        </Col>
        <Col
          span={12}
          style={{
            background: 'linear-gradient(#242B41 0%, #39487D 100%)',
            height: window.innerHeight,
            width: '55%',
          }}
        >
          <div
            style={{
              marginTop: `${this.state.marginTop}%`,
              height: window.innerHeight <= 611 ? '500px' : '580px',
              width: window.innerWidth <= 1025 ? '336px' : '480px',
              marginLeft: '20%',
            }}
          >
            <div className="valientaLogoMain" />
          </div>
        </Col>
      </Row>
    );
  }
}

WebView.propTypes = {
  login: PropTypes.func,
};

export default WebView;
