import { notification } from 'antd';
export default function displayErrorMessage(e, title) {
  if (e.response) {
    const error = e.response;
    if (error.status === 500) {
      notification.error({
        message: title || 'Ocurrio un problema',
        duration: 10,
        description: error.data.message,
      });
    } else if (error.status !== 403) {
      notification.error({
        message: title || 'Ocurrio un problema',
        duration: 10,
        description: `${error.status}: ${error.data.message}`,
      });
    }
  } else {
    notification.error({
      message: 'Error desconocido',
      duration: 10,
      description: `${e.message}`,
    });
  }
}
