import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col, InputNumber, Button } from 'antd';

const text = { fontSize: '1.2em', color: '#23293D' };
const biggerText = { fontSize: '1.6em', color: '#23293D' };

class PackageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pack: {},
    };
  }

  componentDidMount() {
    this.setState({ pack: this.props.package });
  }

  render() {
    const { pack } = this.state;
    return (
      <div>
        <br />
        <Row gutter={24}>
          <Col span={4}>
            <div />
          </Col>
          <Col span={2}>
            <div style={text}>Peso (kg)</div>
          </Col>
          <Col span={2}>
            <div style={text}>Altura (cm)</div>
          </Col>
          <Col span={2}>
            <div style={text}>Ancho (cm)</div>
          </Col>
          <Col span={2}>
            <div style={text}>Largo (cm)</div>
          </Col>
          <Col span={2}>
            <div style={text}># Unidades</div>
          </Col>
          <Col span={8}>
            <div style={text}>Descripción</div>
          </Col>
          <Col span={2}>
            <div />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={4}>
            <div style={biggerText}>Paquete #{this.props.packageNumber + 1}</div>
          </Col>
          <Col span={2}>
            <InputNumber
              value={pack.weight}
              onChange={(e) =>
                this.setState((prevState) => {
                  const thePack = prevState.pack;
                  pack.weight = e;
                  return { pack: thePack };
                })
              }
            />
          </Col>
          <Col span={2}>
            <InputNumber
              value={pack.height}
              onChange={(e) =>
                this.setState((prevState) => {
                  const thePack = prevState.pack;
                  pack.height = e;
                  return { pack: thePack };
                })
              }
            />
          </Col>
          <Col span={2}>
            <InputNumber
              value={pack.width}
              onChange={(e) =>
                this.setState((prevState) => {
                  const thePack = prevState.pack;
                  pack.width = e;
                  return { pack: thePack };
                })
              }
            />
          </Col>
          <Col span={2}>
            <InputNumber
              value={pack.length}
              onChange={(e) =>
                this.setState((prevState) => {
                  const thePack = prevState.pack;
                  pack.length = e;
                  return { pack: thePack };
                })
              }
            />
          </Col>
          <Col span={2}>
            <InputNumber
              value={pack.units}
              onChange={(e) =>
                this.setState((prevState) => {
                  const thePack = prevState.pack;
                  pack.units = e;
                  return { pack: thePack };
                })
              }
            />
          </Col>
          <Col span={8}>
            <Input
              value={pack.description}
              onChange={(e) =>
                this.setState((prevState) => {
                  const thePack = prevState.pack;
                  pack.description = e.target.value;
                  return { pack: thePack };
                })
              }
            />
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              danger
              onClick={() => this.props.removePackage(this.props.packageNumber)}
            >
              Quitar
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
PackageItem.propTypes = {
  packageNumber: PropTypes.number,
  removePackage: PropTypes.func,
  package: PropTypes.object,
};

export default PackageItem;
