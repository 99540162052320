import axios from 'axios';

const ls = require('local-storage');

export const fetchCities = async (cityName, governmentCode) => {
  let url = `${ls.get('gatewayAPI')}/company-service-api/`;
  url += `public/city?page=0&pageSize=10`;
  if (cityName) {
    url += `&cityName=${cityName}`;
  }
  if (governmentCode) {
    url += `&governmentCode=${governmentCode}`;
  }
  try {
    const response = await axios({ method: 'get', url });
    const cities = response.data.map((city) => ({
      value: city.governmentCode,
      label: `${city.name}, ${city.state.name}`,
    }));
    return cities;
  } catch (error) {
    return Promise.reject(error);
  }
};
