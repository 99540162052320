import React from 'react';
import NumberFormat from 'react-number-format';

export function toCurrency(value, colorR) {
  const color = colorR || 'black';
  return (
    <NumberFormat
      value={parseInt(value, 10)}
      displayType="text"
      thousandSeparator
      style={{ color }}
      prefix=" $"
    />
  );
}

export function capitalize(name) {
  if (typeof name !== 'string') return '';
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
