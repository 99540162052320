import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  InputNumber,
  notification,
  DatePicker,
  AutoComplete,
} from 'antd';
import { FormOutlined } from '@ant-design/icons';
import Package from './Package';
import { toCurrency } from '../../utils/formatters';
import { fetchShippingCarriers, fetchPaymentTypes } from '../../gateway/fetchShipment';
import { quoteShipment, saveShipment, createGuide } from '../../gateway/createShipment';
import { fetchCities } from '../../gateway/companyGateway';
import { fetchClients } from '../../gateway/shippingGateway';

const { Option } = Select;

const text = { fontSize: '1.2em', color: '#23293D' };
const bigText = { fontSize: '2em', color: '#23293D' };
const smallText = { fontSize: '1em', color: '#23293D', fontWeight: 'bold' };
const requiredFields = { fontSize: '1.2em', color: '#23293D', fontWeight: 'bold' };

class ShipmentCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingEdit: false,
      isMerchandise: false,
      packages: [],
      shippingCarriers: [],
      paymentTypes: [],
      fetchingShippingCarriers: false,
      fetchingQuotation: false,
      fetchingCities: false,
      savingShipment: false,
      coordinadoraQuotation: undefined,
      servientregaQuotation: undefined,
      fetchingPaymentTypes: false,
      pickupDate: moment(new Date().setDate(new Date().getDate() + 1)),
      cities: [],
      totalValueToPay: 0,
      deliveryTypes: ['NextDay', 'SameDay'],
      clients: [],
      fetchingClients: false,
    };
    this.saveShipment = this.saveShipment.bind(this);
    this.quoteShipment = this.quoteShipment.bind(this);
    this.addPackage = this.addPackage.bind(this);
    this.removePackage = this.removePackage.bind(this);
    this.fetchShippingCarriers = this.fetchShippingCarriers.bind(this);
    this.packagesIncomplete = this.packagesIncomplete.bind(this);
    this.fetchPaymentTypes = this.fetchPaymentTypes.bind(this);
    this.packagesIncomplete = this.packagesIncomplete.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
    this.fetchCities = this.fetchCities.bind(this);
    this.fetchClients = this.fetchClients.bind(this);
  }

  componentDidMount() {
    this.fetchShippingCarriers();
    this.fetchPaymentTypes();
    this.fetchCities('');
    this.fetchClients('');
  }

  async saveShipment() {
    this.setState({ savingShipment: true });
    const firstResult = await saveShipment(
      this.state.shippingCarrier,
      this.state.nameSender,
      this.state.lastNameSender,
      this.state.cellPhoneSender,
      this.state.emailSender,
      this.state.docTypeSender,
      this.state.docSender,
      this.state.street1Sender,
      this.state.street2Sender,
      this.state.indicationsSender,
      this.state.cityCodeSender,
      this.state.nameReceiver,
      this.state.lastNameReceiver,
      this.state.cellPhoneReceiver,
      this.state.emailReceiver,
      this.state.docTypeReceiver,
      this.state.docReceiver,
      this.state.street1Receiver,
      this.state.street2Receiver,
      this.state.indicationsReceiver,
      this.state.cityCodeReceiver,
      this.state.packages,
      this.state.declaredValue,
      this.state.totalValueToPay,
      this.state.isMerchandise,
      this.state.externalId,
      this.state.ownerId,
      this.state.paymentType,
      this.state.pickupDate,
      this.state.countrySender,
      this.state.countryReceiver,
      this.state.deliveryType,
    );
    if (firstResult) {
      const secondResult = await createGuide(firstResult.shipmentId, this.state.ownerId);
      if (secondResult) {
        notification.success({
          message: 'Envío creado',
          description: `Guía: ${secondResult.trackingNumber}`,
          duration: 0,
        });
      }
    }

    this.setState({ savingShipment: false });
  }

  packagesIncomplete() {
    let response = false;
    this.state.packages.forEach((pack) => {
      if (!pack.width || !pack.length || !pack.weight || !pack.height || !pack.units) {
        response = true;
      }
    });
    return response;
  }

  async fetchPaymentTypes() {
    this.setState({ fetchingPaymentTypes: true });
    const { paymentTypes } = await fetchPaymentTypes();
    this.setState({ paymentTypes, fetchingPaymentTypes: false });
  }

  async quoteShipment() {
    if (this.state.packages.length === 0) {
      notification.error({
        message: 'Falta paquete',
        description:
          'Debe agregar almenos un paquete con todos sus datos para realizar una cotización.',
        duration: 5,
      });
    } else if (this.packagesIncomplete()) {
      notification.error({
        message: 'Algún paquete le falta información',
        description: 'Los paquetes deben llevar todos sus datos.',
        duration: 5,
      });
    } else if (
      !this.state.declaredValue ||
      !this.state.cityCodeSender ||
      !this.state.cityCodeReceiver ||
      !this.state.paymentType
    ) {
      notification.error({
        message: 'Faltan campos',
        description: 'Debe llenar todos los campos necesarios para realizar la cotización.',
        duration: 5,
      });
    } else {
      this.setState({ fetchingQuotation: true });
      const {
        cityCodeSender,
        street1Sender,
        street2Sender,
        countrySender,
        cityCodeReceiver,
        street1Receiver,
        street2Receiver,
        countryReceiver,
        declaredValue,
        totalValueToPay,
        paymentType,
        isMerchandise,
        packages,
      } = this.state;
      const { coordinadoraQuotation, servientregaQuotation } = await quoteShipment(
        cityCodeSender,
        street1Sender,
        street2Sender,
        countrySender,
        cityCodeReceiver,
        street1Receiver,
        street2Receiver,
        countryReceiver,
        declaredValue,
        totalValueToPay,
        paymentType,
        isMerchandise,
        packages,
      );
      this.setState({ coordinadoraQuotation, servientregaQuotation, fetchingQuotation: false });
    }
  }

  async fetchShippingCarriers() {
    this.setState({ fetchingShippingCarriers: true });
    const { shippingCarriers } = await fetchShippingCarriers();
    this.setState({ shippingCarriers, fetchingShippingCarriers: false });
  }

  async fetchCities(cityName) {
    this.setState({ fetchingCities: true });
    const cities = await fetchCities(cityName);
    this.setState({ cities, fetchingCities: false });
  }

  async removePackage(index) {
    this.setState((prevState) => {
      const { packages } = prevState;
      packages.splice(index, 1);
      return { packages };
    });
  }

  async fetchClients(alias) {
    this.setState({ fetchingClients: true });
    const { clients } = await fetchClients(1, 10, undefined, alias);
    this.setState({ clients, fetchingClients: false });
  }

  addPackage() {
    this.setState((prevState) => {
      const { packages } = prevState;
      packages.push({});
      return { packages };
    });
  }

  disabledDate(current) {
    return current && current.valueOf() < Date.now();
  }

  handleSearch = (value) => {
    this.fetchCities(value);
  };

  handleClientSearch = (value) => {
    this.fetchClients(value);
  };

  handleClientChange = async (value, type) => {
    const client = this.state.clients[value];
    if (type === 'sender') {
      await this.formatClient(client, type);
    } else if (type === 'receiver') {
      await this.formatClient(client, type);
    }
  };

  async formatClient(client, type) {
    if (type === 'sender') {
      this.setState({
        nameSender: client.name,
        lastNameSender: client.lastName,
        cellPhoneSender: client.phone,
        emailSender: client.email,
        docTypeSender: client.docType,
        docSender: client.docNumber,
        street1Sender: client.street1,
        street2Sender: client.street2,
        indicationsSender: client.indications,
        cityCodeSender: client.cityCode,
        countrySender: client.country,
        postalCodeSender: client.postalCode,
      });
    } else if (type === 'receiver') {
      this.setState({
        nameReceiver: client.name,
        lastNameReceiver: client.lastName,
        cellPhoneReceiver: client.phone,
        emailReceiver: client.email,
        docTypeReceiver: client.docType,
        docReceiver: client.docNumber,
        street1Receiver: client.street1,
        street2Receiver: client.street2,
        indicationsReceiver: client.indications,
        cityCodeReceiver: client.cityCode,
        countryReceiver: client.country,
        postalCodeReceiver: client.postalCode,
      });
    }
  }

  render() {
    return (
      <div style={{ marginLeft: '2%', marginRight: '2%' }}>
        <FormOutlined className="component-icons" />
        <div className="component-title">Cotización/Creación envíos</div>
        <br />
        <div style={smallText}>Los campos con * son requeridos para para crear un envio</div>
        <br />
        <br />
        <Row gutter={24}>
          <Col span={8}></Col>
          <Col span={4}>
            <Button
              key="submit"
              type="primary"
              loading={this.state.savingShipment}
              onClick={this.saveShipment}
            >
              Crear Envío
            </Button>
          </Col>
          <Col span={4}>
            <Button
              key="submit"
              type="primary"
              loading={this.state.fetchingQuotation}
              onClick={this.quoteShipment}
            >
              Cotizar Envío
            </Button>
          </Col>
          <Col span={8}></Col>
        </Row>
        <br />
        <Row gutter={24} style={{ marginLeft: '15%', marginRight: '15%' }}>
          <Col span={24}>
            Valor cotizado servientrega: {toCurrency(this.state.servientregaQuotation)}
          </Col>
        </Row>
        <Row gutter={24} style={{ marginLeft: '15%', marginRight: '15%' }}>
          <Col span={24}>
            Valor cotizado coordinadora: {toCurrency(this.state.coordinadoraQuotation)}
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Button
          key="submit"
          type="primary"
          loading={this.state.loadingEdit}
          onClick={this.addPackage}
        >
          Agregar Paquete
        </Button>
        <br />
        {this.state.packages.map((p, i) => (
          <Package
            key={Math.floor(Math.random() * 100000)}
            packageNumber={i}
            removePackage={this.removePackage}
            package={p}
          ></Package>
        ))}
        <br />
        <br />
        <br />
        <div>
          <div style={bigText}>Datos Generales</div>
          <br />
          <br />
          <Row gutter={24}>
            <Col span={4}>
              <div style={requiredFields}>Fecha recogida *</div>
            </Col>
            <Col span={4}>
              <div style={text}>Id Externo (Id del packing)</div>
            </Col>
            <Col span={4}>
              <div style={requiredFields}>Tipo de pago</div>
            </Col>
            <Col span={2}>
              <div style={requiredFields}>Valor declarado</div>
            </Col>
            <Col span={2}>
              <div style={requiredFields}>Valor total a pagar</div>
            </Col>
            <Col span={4}>
              <div style={text}>Transportadora</div>
            </Col>
            <Col span={2}>
              <div style={requiredFields}>Es Mercancía</div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={4}>
              <div style={{ fontSize: 'medium' }}>
                <DatePicker
                  disabledDate={this.disabledDate}
                  style={{ marginLeft: '1em', marginRight: '1em' }}
                  onChange={(date) => this.setState({ pickupDate: moment(date).startOf('day') })}
                  format="YYYY-MM-DD"
                  value={this.state.pickupDate}
                />
              </div>
            </Col>
            <Col span={4}>
              <Input
                value={this.state.externalId}
                onChange={(e) => this.setState({ externalId: e.target.value })}
              />
            </Col>
            <Col span={4}>
              <Select
                placeholder="Seleccione"
                style={{ width: 200 }}
                filterOption={false}
                loading={this.state.fetchingPaymentTypes}
                onChange={(e) => this.setState({ paymentType: e })}
              >
                {this.state.paymentTypes.map((paymentType) => (
                  <Option key={paymentType.value}>{paymentType.value}</Option>
                ))}
              </Select>
            </Col>
            <Col span={2}>
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={this.state.declaredValue}
                onChange={(e) => this.setState({ declaredValue: e })}
              />
            </Col>
            <Col span={2}>
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                value={this.state.totalValueToPay}
                onChange={(e) => this.setState({ totalValueToPay: e })}
              />
            </Col>
            <Col span={4}>
              <Select
                placeholder="Seleccione"
                style={{ width: 200 }}
                filterOption={false}
                loading={this.state.fetchingShippingCarriers}
                onChange={(e) => this.setState({ shippingCarrier: e })}
              >
                {this.state.shippingCarriers.map((shippingCarrier) => (
                  <Option key={shippingCarrier.id}>{shippingCarrier.name}</Option>
                ))}
              </Select>
            </Col>
            <Col span={2}>
              <Checkbox
                style={text}
                onChange={() => this.setState((prev) => ({ isMerchandise: !prev.isMerchandise }))}
                checked={this.state.isMerchandise}
              ></Checkbox>
            </Col>
          </Row>
          <br />
          <Row gutter={24}>
            {this.props.role === 'ADMIN_USER' ? (
              <Col span={4}>
                <div style={text}>Id de la compañia</div>
              </Col>
            ) : (
              <div />
            )}
            <Col span={4}>
              <div style={text}>Tipo de entrega (99 minutos)</div>
            </Col>
          </Row>
          <Row>
            {this.props.role === 'ADMIN_USER' ? (
              <Col span={4}>
                <Input
                  value={this.state.ownerId}
                  onChange={(e) => this.setState({ ownerId: e.target.value })}
                />
              </Col>
            ) : (
              <div />
            )}
            <Col span={4}>
              <Select
                placeholder="Seleccione"
                style={{ width: 200 }}
                filterOption={false}
                onChange={(e) => this.setState({ deliveryType: e })}
              >
                {this.state.deliveryTypes.map((type) => (
                  <Option key={type}>{type}</Option>
                ))}
              </Select>
            </Col>
          </Row>
          <br />
          <br />
          <div>
            <Row gutter={24}>
              <Col span={11}>
                <div style={bigText}>Remitente</div>
                <Select
                  showSearch
                  placeholder="Buscar"
                  style={{ width: 200 }}
                  loading={this.state.fetchingClients}
                  onSearch={this.handleClientSearch}
                  filterOption={(inputValue, option) =>
                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  }
                  onChange={(e) => this.handleClientChange(e, 'sender')}
                >
                  {this.state.clients.map((client, index) => (
                    <Option key={client.id} value={index}>
                      {client.alias}
                    </Option>
                  ))}
                </Select>
                <br />
                <Row gutter={24}>
                  <Col span={8}>
                    <div style={text}>
                      <b>Nombre*</b>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>
                      <b>Apellido</b>
                    </div>
                  </Col>
                  <Col span={182}>
                    <div style={text}>Email</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <Input
                      value={this.state.nameSender}
                      onChange={(e) => this.setState({ nameSender: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.lastNameSender}
                      onChange={(e) => this.setState({ lastNameSender: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.emailSender}
                      onChange={(e) => this.setState({ emailSender: e.target.value })}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={12}>
                    <div style={text}>Celular *</div>
                  </Col>
                  <Col span={12}>
                    <div style={text}>Identificación</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Select
                      style={{ width: '50%' }}
                      defaultValue={this.state.cellphoneCountrySender}
                      value="+57 (Colombia)"
                      onChange={(value) => this.setState({ cellphoneCountrySender: value })}
                    >
                      <Option value="Colombia" key="Colombia">
                        +57 (Colombia)
                      </Option>
                    </Select>
                    <Input
                      style={{ width: '50%' }}
                      type="number"
                      value={this.state.cellPhoneSender}
                      onChange={(e) => this.setState({ cellPhoneSender: e.target.value })}
                    />
                  </Col>
                  <Col span={12}>
                    <Select
                      style={{ width: '50%' }}
                      defaultValue={this.state.docTypeSender}
                      onChange={(value) => this.setState({ docTypeSender: value })}
                      value="CC"
                    >
                      <Option value="CC" key="CC">
                        CC
                      </Option>
                    </Select>
                    <Input
                      style={{ width: '50%' }}
                      value={this.state.docSender}
                      onChange={(e) => this.setState({ docSender: e.target.value })}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={8}>
                    <div style={text}>Calle 1 *</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Calle 2</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Indicaciones</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <Input
                      value={this.state.street1Sender}
                      onChange={(e) => this.setState({ street1Sender: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.street2Sender}
                      onChange={(e) => this.setState({ street2Sender: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.indicationsSender}
                      onChange={(e) => this.setState({ indicationsSender: e.target.value })}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={8}>
                    <div style={requiredFields}>Código ciudad *</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Código postal</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>País *</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <AutoComplete
                      style={{ width: 155 }}
                      options={this.state.cities}
                      filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(e) => this.setState({ cityCodeSender: e })}
                      onSearch={this.handleSearch}
                      loading={this.state.fetchingCities}
                      value={this.state?.cityCodeSender}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.postalCodeSender}
                      onChange={(e) => this.setState({ postalCodeSender: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.countrySender}
                      onChange={(e) => this.setState({ countrySender: e.target.value })}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <div style={bigText}>Destinatario</div>
                <Select
                  showSearch
                  placeholder="Buscar"
                  style={{ width: 200 }}
                  loading={this.state.fetchingClients}
                  onSearch={this.handleClientSearch}
                  filterOption={(inputValue, option) =>
                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                  }
                  onChange={(e) => this.handleClientChange(e, 'receiver')}
                >
                  {this.state.clients.map((client, index) => (
                    <Option key={client.id} value={index}>
                      {client.alias}
                    </Option>
                  ))}
                </Select>
                <br />
                <Row gutter={24}>
                  <Col span={8}>
                    <div style={text}>Nombre *</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Apellido</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Email</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <Input
                      value={this.state.nameReceiver}
                      onChange={(e) => this.setState({ nameReceiver: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.lastNameReceiver}
                      onChange={(e) => this.setState({ lastNameReceiver: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.emailReceiver}
                      onChange={(e) => this.setState({ emailReceiver: e.target.value })}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={12}>
                    <div style={text}>Celular *</div>
                  </Col>
                  <Col span={12}>
                    <div style={text}>Identificación *</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={12}>
                    <Select
                      style={{ width: '50%' }}
                      defaultValue={this.state.cellphoneCountryReceiver}
                      value="+57 (Colombia)"
                      onChange={(value) => this.setState({ cellphoneCountryReceiver: value })}
                    >
                      <Option value="Colombia" key="Colombia">
                        +57 (Colombia)
                      </Option>
                    </Select>
                    <Input
                      style={{ width: '50%' }}
                      type="number"
                      value={this.state.cellPhoneReceiver}
                      onChange={(e) => this.setState({ cellPhoneReceiver: e.target.value })}
                    />
                  </Col>
                  <Col span={12}>
                    <Select
                      style={{ width: '50%' }}
                      defaultValue={this.state.docTypeReceiver}
                      onChange={(value) => this.setState({ docTypeReceiver: value })}
                      value="CC"
                    >
                      <Option value="CC" key="CC">
                        CC
                      </Option>
                    </Select>
                    <Input
                      style={{ width: '50%' }}
                      value={this.state.docReceiver}
                      onChange={(e) => this.setState({ docReceiver: e.target.value })}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={8}>
                    <div style={text}>Calle 1 *</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Calle 2 *</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Indicaciones</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <Input
                      value={this.state.street1Receiver}
                      onChange={(e) => this.setState({ street1Receiver: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.street2Receiver}
                      onChange={(e) => this.setState({ street2Receiver: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.indicationsReceiver}
                      onChange={(e) => this.setState({ indicationsReceiver: e.target.value })}
                    />
                  </Col>
                </Row>
                <br />
                <Row gutter={24}>
                  <Col span={8}>
                    <div style={requiredFields}>Código ciudad *</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>Código postal</div>
                  </Col>
                  <Col span={8}>
                    <div style={text}>País *</div>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={8}>
                    <AutoComplete
                      style={{ width: 155 }}
                      options={this.state.cities}
                      filterOption={(inputValue, option) =>
                        option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={(e) => this.setState({ cityCodeReceiver: e })}
                      onSearch={this.handleSearch}
                      loading={this.state.fetchingCities}
                      value={this.state?.cityCodeReceiver}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.postalCodeReceiver}
                      onChange={(e) => this.setState({ postalCodeReceiver: e.target.value })}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      value={this.state.countryReceiver}
                      onChange={(e) => this.setState({ countryReceiver: e.target.value })}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }
}
ShipmentCard.propTypes = {
  role: PropTypes.string,
};
export default ShipmentCard;
