import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import DisplayErrorMessage from './components/Utils/Errors/DisplayErrorMessage';

import SideNav from './components/SideNav/SideNav';

import Login from './components/LoginLogout/Login';
import Logout from './components/LoginLogout/Logout';
import Pickup from './components/Pickup/Pickup';
import ShipmentQuery from './components/Shipment/ShipmentQuery';
import ShipmentCreation from './components/Shipment/ShipmentCreation';
import ValidateRoute from './components/Route/ValidateRoute';
import Clients from './components/Client/ClientCreation';
import ClientQuery from './components/Client/ClientQuery';

const ls = require('local-storage');
const axios = require('axios');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.setRole = this.setRole.bind(this);
  }

  async login(authToken, refreshToken, role) {
    ls.set('refreshToken', refreshToken);
    ls.set('authToken', authToken);
    await this.setRole(role);
    this.setState({ loggedIn: true });
  }

  logout() {
    ls.clear('authToken');
    ls.clear('refreshToken');
    this.setState({ loggedIn: false });
  }

  async setRole(role) {
    if (role) {
      this.setState({ role });
    } else {
      const url = `${ls.get('gatewayAPI')}/identity-service-api/role`;
      const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
      try {
        const result = await axios({ method: 'get', url, headers });
        this.setState({ role: result.data[0] });
      } catch (e) {
        DisplayErrorMessage(e.response.data.message);
      }
    }
  }

  setApiGatewayURL() {
    const { origin } = window.location;
    let gatewayAPI = process.env.REACT_APP_GATEWAY_SERVICE_API;
    if (!gatewayAPI) {
      const baseURL = 'https://gateway-service-api.';
      const valientaURL = '.valienta.co';
      let environment = origin.split('.')[1];
      if (environment === 'valienta') environment = 'prod';
      gatewayAPI = baseURL + environment + valientaURL;
    }
    ls.set('gatewayAPI', gatewayAPI);
  }

  render() {
    this.setApiGatewayURL();

    if (!this.state.loggedIn) {
      return <Login login={this.login} />;
    }

    const componentToRender = (
      <Switch>
        <Route path="/consulta-envios" component={() => <ShipmentQuery role={this.state.role} />} />
        <Route
          path="/creacion-envios"
          component={() => <ShipmentCreation role={this.state.role} />}
        />
        <Route path="/recogidas" component={() => <Pickup role={this.state.role} />} />
        <Route path="/validar-ruta" component={() => <ValidateRoute />} />
        <Route path="/clientes" component={() => <Clients role={this.state.role} />} />
        <Route path="/cerrar" component={() => <Logout logout={this.logout} />} />
        <Route
          path="/consultar-clientes"
          component={() => <ClientQuery role={this.state.role} />}
        />
        <Redirect push to="/consulta-envios" />
      </Switch>
    );
    return (
      <BrowserRouter>
        <SideNav component={componentToRender} />
      </BrowserRouter>
    );
  }
}

export default App;
