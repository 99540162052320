/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Table, Row, Col, Tooltip, notification } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined, EditFilled } from '@ant-design/icons';
import './css/ClientQuery.css';
import { deleteClients, fetchClients } from '../../gateway/shippingGateway';

class ClientQuery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pagination: { current: 1, pageSize: 10 },
      clients: [],
      docNumber: '',
      alias: '',
      name: '',
      lastName: '',
      email: '',
      ownerId: '',
    };
    this.fetchClients = this.fetchClients.bind(this);
    this.reload = this.reload.bind(this);
  }

  getCols() {
    return [
      {
        title: 'Id',
        dataIndex: '',
        key: 'id',
        render: (s) => <div>{s.id || '-'}</div>,
      },
      {
        title: 'Alias',
        dataIndex: '',
        render: (s) => <div>{s.alias || '-'}</div>,
        key: 'alias',
      },
      {
        title: 'Nombre',
        dataIndex: '',
        render: (s) => <div>{s.name || '-'}</div>,
        key: 'name',
      },
      {
        title: 'Apellido',
        dataIndex: '',
        render: (s) => <div>{s.lastName || '-'}</div>,
        key: 'lastName',
      },
      {
        title: 'Calle 1',
        dataIndex: '',
        render: (s) => <div>{s.street1 || '-'}</div>,
        key: 'street1',
      },
      {
        title: 'Calle 2',
        dataIndex: '',
        render: (s) => <div>{s.street2 || '-'}</div>,
        key: 'street2',
      },
      {
        title: 'Indicaciones',
        dataIndex: '',
        render: (s) => <div>{s.indications || '-'}</div>,
        key: 'indications',
      },
      {
        title: 'Telefono',
        dataIndex: '',
        render: (s) => <div>{s.phone || '-'}</div>,
        key: 'phone',
      },
      {
        title: 'Correo',
        dataIndex: '',
        render: (s) => <div>{s.email || '-'}</div>,
        key: 'email',
      },
      {
        title: 'Acciones',
        dataIndex: '',
        render: (s) => (
          <div>
            <Row gutter={24}>
              <Col span={12}>
                <Tooltip title="Editar" envío>
                  <Link
                    to={{
                      pathname: '/clientes',
                      client_data: s,
                    }}
                  >
                    <Button type="primary" icon={<EditFilled />} />
                  </Link>
                </Tooltip>
              </Col>
              <Col span={12}>
                <Tooltip title="Eliminar" envío>
                  <Button
                    type="primary"
                    icon={<CloseOutlined />}
                    danger
                    onClick={() => this.deleteClient(s.id)}
                  />
                </Tooltip>
              </Col>
            </Row>
          </div>
        ),
        key: 'id',
      },
    ];
  }

  reload() {
    this.setState(
      {
        pagination: { current: 1, pageSize: 10 },
        clients: [],
      },
      () => this.fetchClients(this.state.pagination),
    );
  }

  async fetchClients(params) {
    const { current, pageSize } = params;

    this.setState({ loading: true, clients: [] });

    const { docNumber, alias, email, ownerId, name, lastName } = this.state;

    const { clients, totalPages } = await fetchClients(
      current,
      pageSize,
      docNumber,
      alias,
      email,
      ownerId,
      name,
      lastName,
    );

    const pagination = { ...params, total: totalPages };
    this.setState({
      loading: false,
      clients,
      pagination,
    });
  }

  async deleteClient(id) {
    const message = await deleteClients(id);

    if (message === 'OK') {
      notification.success({
        message,
        description: `Id: ${id} fue eliminado`,
        duration: 5,
      });
      this.reload();
    } else {
      notification.error({
        message: 'Error',
        description: `Id: ${id} no se pudo eliminar`,
        duration: 5,
      });
    }
  }

  componentDidMount() {
    this.fetchClients(this.state.pagination);
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <div className="component-title">Clientes</div>
        <br />
        <br />
        <div className="formContainer">
          <Form onFinish={this.reload}>
            <Form.Item
              label="Alias"
              name="alias"
              style={{
                display: 'inline-block',
                width: 'calc(16% - 12px)',
              }}
            >
              <Input
                onChange={(e) => this.setState({ alias: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label="Nombre"
              name="name"
              style={{
                display: 'inline-block',
                width: 'calc(16% - 12px)',
              }}
            >
              <Input
                onChange={(e) => this.setState({ name: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label="Apellido"
              name="lastName"
              style={{
                display: 'inline-block',
                width: 'calc(16% - 12px)',
              }}
            >
              <Input
                onChange={(e) => this.setState({ lastName: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label="Documento"
              name="docNumber"
              style={{
                display: 'inline-block',
                width: 'calc(16% - 12px)',
              }}
            >
              <Input
                onChange={(e) => this.setState({ docNumber: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="email"
              style={{
                display: 'inline-block',
                width: 'calc(16% - 12px)',
              }}
            >
              <Input
                onChange={(e) => this.setState({ email: e.target.value })}
                style={{ width: 200 }}
              />
            </Form.Item>
            {this.props.role === 'ADMIN_USER' ? (
              <Form.Item
                label="Id propietario"
                name="ownerId"
                style={{
                  display: 'inline-block',
                  width: 'calc(16% - 12px)',
                }}
              >
                <Input
                  onChange={(e) => this.setState({ ownerId: e.target.value })}
                  style={{ width: 200 }}
                />
              </Form.Item>
            ) : (
              <div />
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Buscar
              </Button>
            </Form.Item>
          </Form>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div className="row">
          <h3 style={{ padding: '0 20px' }}>
            Total clientes encontrados: {this.state.pagination.total || 0}
          </h3>
        </div>
        <br />
        <br />

        <Table
          scroll={{ x: 'max-content' }}
          dataSource={this.state.clients}
          pagination={this.state.pagination}
          rowKey={(client) => client.id}
          onChange={this.fetchClients}
          columns={this.getCols()}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

ClientQuery.propTypes = {
  role: PropTypes.string,
};

export default ClientQuery;
