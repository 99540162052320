/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import './css/ClientCreation.css';
import { Form, Input, Button, Select, notification, AutoComplete } from 'antd';
import { saveClient, updateClient } from '../../gateway/shippingGateway';
import { fetchCities } from '../../gateway/companyGateway';

const { Option } = Select;

class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: [],
      fetchingCities: false,
      client: this.props.location.client_data ? this.props.location.client_data : undefined,
    };
    this.fetchCities = this.fetchCities.bind(this);
    this.onFinish = this.onFinish.bind(this);
  }

  async fetchCities(cityName) {
    this.setState({ fetchingCities: true });
    const cities = await fetchCities(cityName);
    this.setState({ cities, fetchingCities: false });
  }

  async onFinish(values) {
    if (this.state.client) {
      await updateClient(this.state.client.id, values);
      notification.success({
        message: 'Cliente creado',
        duration: 0,
      });
    } else {
      await saveClient(values);
      notification.success({
        message: 'Cliente creado',
        duration: 0,
      });
    }
  }

  componentDidMount() {
    this.fetchCities('');
  }

  handleSearch = (value) => {
    this.fetchCities(value);
  };

  render() {
    const prefixSelector = (
      <Form.Item name="prefix" noStyle>
        <Select
          style={{
            width: 70,
          }}
        >
          <Option value="57">+57</Option>
        </Select>
      </Form.Item>
    );

    const docType = (
      <Form.Item name="docType" noStyle>
        <Select
          style={{
            width: 70,
          }}
        >
          <Option value="CC">CC</Option>
        </Select>
      </Form.Item>
    );

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
    };

    return (
      <div className="formContainer">
        <h1>Crear cliente</h1>
        <Form {...layout} onFinish={this.onFinish} className="form" colon="rigth-align">
          <Form.Item label="Alias" name="alias">
            {this.state.client ? <Input defaultValue={this.state.client.alias} /> : <Input />}
          </Form.Item>
          <Form.Item label="Nombre" required name="name">
            {this.state.client ? <Input defaultValue={this.state.client.name} /> : <Input />}
          </Form.Item>
          <Form.Item label="Apellido" name="lastName">
            {this.state.client ? <Input defaultValue={this.state.client.lastName} /> : <Input />}
          </Form.Item>
          <Form.Item label="Telefono" required name="phone">
            {this.state.client ? (
              <Input addonBefore={prefixSelector} defaultValue={this.state.client.phone} />
            ) : (
              <Input addonBefore={prefixSelector} />
            )}
          </Form.Item>
          <Form.Item label="E-mail" name="email">
            {this.state.client ? <Input defaultValue={this.state.client.lastName} /> : <Input />}
          </Form.Item>
          <Form.Item label="Documento" name="docNumber">
            {this.state.client ? (
              <Input addonBefore={docType} defaultValue={this.state.client.docNumber} />
            ) : (
              <Input addonBefore={docType} />
            )}
          </Form.Item>
          <Form.Item
            required
            label="Dirección"
            name="street1"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="street1"
              style={{
                display: 'inline-block',
                width: 'calc(25% - 12px)',
              }}
            >
              {this.state.client ? <Input defaultValue={this.state.client.street1} /> : <Input />}
            </Form.Item>
            <span
              style={{
                display: 'inline-block',
                width: '24px',
                lineHeight: '32px',
                textAlign: 'center',
              }}
            >
              -
            </span>
            <Form.Item
              name="street2"
              style={{
                display: 'inline-block',
                width: 'calc(25% - 12px)',
              }}
            >
              {this.state.client ? <Input defaultValue={this.state.client.street2} /> : <Input />}
            </Form.Item>
            <Form.Item
              name="indications"
              style={{
                display: 'inline-block',
                width: 'calc(50% - 12px)',
              }}
            >
              {this.state.client ? (
                <Input defaultValue={this.state.client.indications} />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item label="Ciudad" required name="cityCode">
            {this.state.client ? (
              <AutoComplete
                options={this.state.cities}
                filterOption={(inputValue, option) =>
                  option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                defaultValue={this.state.client.cityCode}
                onSearch={this.handleSearch}
                loading={this.state.fetchingCities}
              />
            ) : (
              <AutoComplete
                options={this.state.cities}
                filterOption={(inputValue, option) =>
                  option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSearch={this.handleSearch}
                loading={this.state.fetchingCities}
              />
            )}
          </Form.Item>
          <Form.Item label="País" required name="country">
            {this.state.client ? <Input defaultValue={this.state.client.country} /> : <Input />}
          </Form.Item>
          <Form.Item label="Código postal" name="postalCode">
            {this.state.client ? <Input defaultValue={this.state.client.postalCode} /> : <Input />}
          </Form.Item>
          {this.props.role === 'ADMIN_USER' ? (
            <Form.Item label="Id propietario" name="ownerId">
              {this.state.client ? <Input defaultValue={this.state.client.ownerId} /> : <Input />}
            </Form.Item>
          ) : (
            <div />
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Crear
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

ClientForm.propTypes = {
  role: PropTypes.string,
  location: PropTypes.object,
};

export default withRouter(ClientForm);
